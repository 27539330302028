<template>
  <!-- 团体比赛成绩详情 -->
  <div class="TeamCompetition" id="printRoot">
    <div class="detaBox">
      <div class="rowBox">
        <div class="rightBox">比赛项目：</div>
        <div class="leftBox">2022年成都市青少年气步枪射击锦标赛</div>
      </div>
      <div class="rowBox">
        <div class="rightBox">比赛类型：</div>
        <div class="leftBox">男子组10米气步枪资格赛</div>
      </div>
      <div class="rowBox">
        <div class="rightBox">团体数量：</div>
        <div class="leftBox">24</div>
      </div>
      <div class="rowBox">
        <div class="rightBox">团体人数：</div>
        <div class="leftBox">5</div>
      </div>
      <div class="rowBox">
        <div class="rightBox">比赛发数：</div>
        <div class="leftBox">60</div>
      </div>
    </div>
    <div class="tableContent">
      <div class="searchBox">
        <el-input
          placeholder="请输入选手姓名"
          v-model="value"
          clearable
        ></el-input>
        <!-- </div> -->
        <!-- <div class="btn"> -->
        <el-button class="seaBtn" type="primary" size="small" @click="search"
          >查询</el-button
        >
        <el-button type="primary" size="small" v-print="{ id: 'printRoot' }"
          >打印</el-button
        >
        <!-- </div> -->
      </div>
      <el-table :data="tableData" border size="small">
        <el-table-column prop="id" label="团体排名" width="80"> </el-table-column>
        <el-table-column prop="username" label="团体名称" width="120">
        </el-table-column>
        <el-table-column prop="project" label="团体人数"> </el-table-column>
        <el-table-column prop="danwei" label="比赛项目" width="120">
        </el-table-column>
        <el-table-column prop="sum" label="单位" width="120"> </el-table-column>
        <el-table-column prop="sumz" label="总环数" width="120">
        </el-table-column>
        <el-table-column class-name="czBox" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="details(scope.row.id)"
              >查看成绩</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamCompetition',
  components: {},
  props: {},
  data() {
    return {
      formData: {
        field101: undefined,
        field106: undefined,
        field107: undefined,
        field108: undefined,
        field109: undefined,
      },
      value: '',
      tableData: [
        {
          id: 1,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 2,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 1,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 2,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 1,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 2,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 1,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 2,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 1,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 2,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 1,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 2,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 1,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 2,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 1,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
        {
          id: 2,
          username: '123456',
          project: '222222',
          danwei: '金牛区',
          sum: '10',
          sumz: '99'
        },
      ], //用户列表
    }
  },
  computed: {},
  watch: {},
  created() { },
  methods: {
    // 查看详情
    details(id) {
      console.log(id);
      console.log(this.tableData.length);
      this.$router.push('/home/scoreQuery/details/personalDetails');
    },
    search() { },
    add() { },

    // -----------------------
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  }
}
</script>

<style lang="scss" scoped>
.TeamCompetition {
  padding: 20px;
  .detaBox {
    .rowBox {
      display: flex;
      margin-bottom: 30px;
      .rightBox {
        width: 90px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 20px;
        text-align: right;
      }
      .leftBox {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        line-height: 20px;
      }
    }
  }
  .tableContent {
    margin-top: 20px;
    .searchBox {
      margin-bottom: 15px;
      float: right;

      .el-input {
        width: 180px;
        margin-right: 15px;
        // margin-bottom: 20px;
      }
      .el-button {
        background: #06206b;
      }
      .seaBtn {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    .el-table--border {
      // border-bottom: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
    }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }
}
@media print {
  body {
    // -webkit-print-color-adjust: exact;
    // -moz-print-color-adjust: exact;
    // -ms-print-color-adjust: exact;
    // print-color-adjust: exact;
    margin: 0 auto;
    #printRoot {
      background-color: #fff;
      padding-top: 350px;
    }
    .TeamCompetition {
      padding: 200px;
      .detaBox {
        .rowBox {
          display: flex;
          margin-bottom: 300px;
          .rightBox {
            width: 900px;
            height: 200px;
            font-size: 140px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            line-height: 200px;
            text-align: right;
          }
          .leftBox {
            height: 200px;
            font-size: 140px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7d7d7d;
            line-height: 200px;
          }
        }
      }
      .tableContent {
        margin-top: 200px;
        .searchBox {
          height: 0;
        }

        .el-table--border {
          // border-bottom: 1px solid #ebeef5;
          border-right: 10px solid #ebeef5;
        }
        ::v-deep table {
          width: 100%;
          tr {
            th,
            td {
              height: 360px;
              .cell {
                height: 350px;
                line-height: 350px;
                padding: 0 40px;
              }
            }
          }
        }
      }
    }
  }
}
@page {
  /* 打印A4大小 */
  size: auto A4 landscape;
  margin: 0 auto;
}
// 兼容火狐
::v-deep .el-table__body-wrapper {
  overflow-y: scroll;
  scrollbar-color: #d7d7d7 transparent;
  scrollbar-width: thin;
}
// 兼容谷歌
// 滚动条的宽度
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}
// 滚动条的滑块
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 3px;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  background: #fff !important;
}
</style>
